<template>
  <div>
    <div class="app-wrapper">
      <div class="container py-5">
        <div class="col-lg-10 mx-auto p-0">
          <div class="py-5 d-flex align-items-center justify-content-between">
            <h3 class="ana_h3 mb-0">Redirect Link</h3>
            <div>
              <span class="cancel" @click="$router.go(-1)">
                <i class="ti-close"></i>
              </span>
            </div>
          </div>
          <div
            class="mt-3 mb-3 d-flex align-items-center justify-content-center py-5"
            v-if="isLoading"
          >
            <div class="loader"></div>
          </div>
          <div v-if="!isLoading">
            <div class="py-3">
              <div class="mb-3">
                <small class="text-muted">You are editing </small>
              </div>
              <p class="small font-weight-bold mb-0 text-muted">
                {{ link.longURL }}
              </p>
              <p class="font-weight-bold ana_paragraph">{{ link.title }}</p>
            </div>
            <form class="form" @submit.prevent="updateURL">
              <div class="form-group">
                <div class="input-group mb-3 ana-input__group">
                  <div class="input-group-prepend">
                    <span class="input-group-text" id="basic-addon2"
                      ><i class="ti-unlink"></i
                    ></span>
                  </div>
                  <input
                    type="text"
                    name="long_url"
                    class="form-control ana-input"
                    v-model.trim="$v.longURL.$model"
                    placeholder="tesla.com"
                    required
                  />
                </div>
              </div>
              <div class="mt-3 mb-3" v-if="isLinkLoading">
                <div class="linear-loader"></div>
              </div>
              <button
                type="submit"
                class="ana-btn ana-btn__fill"
                :disabled="isLinkLoading || $v.$invalid"
              >
                Redirect URL
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import isURL from "@/middleware/isURL";
import { required, minLength, between, email } from "vuelidate/lib/validators";
export default {
  metaInfo: {
    title: "Redirect Link - Ana app",
  },
  name: "Redirect",
  middleware: "auth",
  data() {
    return {
      link: {},
      longURL: "",
      isLoading: false,
      isLinkLoading: false,
    };
  },
  validations: {
    longURL: {
      required,
    },
  },
  mounted() {
    this.isLoading = true;
    const linkID = this.$route.params.id;
    axios
      .get(`my-links/${linkID}`)
      .then((res) => {
        this.link = res.data.link;
        this.longURL = res.data.link.longURL;
        this.isLoading = false;
      })
      .catch((err) => {
        console.log(err);
        this.isLoading = false;
        this.$router.push("/dash/overview");
      });
  },
  methods: {
    updateURL() {
      let longURL;
      if (
        this.longURL.indexOf("http://") == 0 ||
        this.longURL.indexOf("https://") == 0
      ) {
        longURL = this.longURL;
      } else {
        longURL = `http://${this.longURL}`;
      }
      this.isLinkLoading = true;
      if (isURL.verify(longURL)) {
        const updated_link = {
          longURL: longURL,
        };
        axios
          .put(`my-links/redirect/${this.link._id}`, updated_link)
          .then((res) => {
            this.isLinkLoading = false;
            this.$toast(`Link has been redirected successfully`, {
              timeout: 2000,
              type: "success",
            });
            this.$router.push({
              path: `/dash/link/${this.link._id}/${res.data.link.slug}`,
              params: {
                linkID: this.link._id,
              },
            });
          })
          .catch((err) => {
            this.isLinkLoading = false;
            this.$toast(`${err.response.data.message}`, {
              timeout: 6000,
              type: "error",
            });
            console.log(err);
          });
      } else {
        this.isLinkLoading = false;
        this.$toast(`Be sure to provide a valid URL`, {
          timeout: 6000,
          type: "error",
        });
      }
    },
  },
};
</script>
